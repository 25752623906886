<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="CRM" subtitle="| Informe de Cotizaciones" class="heading-block">
      <template #extra>
        <div class="mt-5 mt-sm-0 ml-sm-3">
        </div>
      </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row class="p-2">
          <b-col sm="12">
            <label class="mt-3">Seleccione un periodo:</label>
          </b-col>
          <b-col sm="2">
            <label class="mt-2" for="datepicker-dateformat1">Fecha inicio</label>
            <b-form-datepicker id="datepicker-dateformat1" v-model="from" placeholder="Seleccione..." :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es"></b-form-datepicker>
          </b-col>
          <b-col sm="2">
            <label class="mt-2" for="datepicker-dateformat2">Fecha fin</label>
            <b-form-datepicker id="datepicker-dateformat2" v-model="to" placeholder="Seleccione..." :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es"></b-form-datepicker>
          </b-col>
          <b-col sm="1">
            <label class="mt-2 w-100">&nbsp;</label>
            <button class="btn btn-primary"  @click="Procesar(false)">Procesar</button>
          </b-col>
          <b-col sm="2">
            <label class="mt-2 w-100">&nbsp;</label>
            <button class="btn btn-primary"  @click="Procesar(true)">Ver todas</button>
          </b-col>
        </b-row>
        <div v-if="isLoading" style="min-height:500px">
          <p>Cargando...</p>
        </div>
        <div v-if="!isLoading">
          <b-row class="mt-5">
            <b-col sm="8" class="offset-sm-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>Estatus</th>
                    <th>Cantidad</th>
                    <th>Porcentaje</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Facturado</th>
                    <td>{{ stats.total_facturado }}</td>
                    <td>{{ ((stats.total_facturado * 100) / stats.total_rows).toFixed(2)  }}%</td>
                    <td>$ {{ moneyFormat.format(stats.total_rows_money_facturado_quantity) }}</td>
                  </tr>
                  <tr>
                    <th>Aceptadas</th>
                    <td>{{ stats.total_aceptado }}</td>
                    <td>{{ ((stats.total_aceptado * 100) / stats.total_rows).toFixed(2)  }}%</td>
                    <td>$ {{ moneyFormat.format(stats.total_rows_money_aceptado_quantity) }}</td>
                  </tr>
                  <tr>
                    <th>Seguimiento</th>
                    <td>{{ stats.total_seguimiento }}</td>
                    <td>{{ ((stats.total_seguimiento * 100) / stats.total_rows).toFixed(2)  }}%</td>
                    <td>$ {{ moneyFormat.format(stats.total_rows_money_seguimiento_quantity) }}</td>
                  </tr>
                  <tr>
                    <th>Rechazadas</th>
                    <td>{{ stats.total_rechazado }}</td>
                    <td>{{ ((stats.total_rechazado * 100) / stats.total_rows).toFixed(2)  }}%</td>
                    <td>$ {{ moneyFormat.format(stats.total_rows_money_rechazado_quantity) }}</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td>{{ stats.total_rows }}</td>
                    <td>100%</td>
                    <td>$ {{ moneyFormat.format(stats.total_rows_money_quantity) }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col sm="6" xl="2">
              <b-dropdown id="dropdown-default-light" variant="light" text="Acciones agrupadas">
                <b-dropdown-item class="font-size-sm">Habilitar Seleccion</b-dropdown-item>
                <b-dropdown-item class="font-size-sm">Deshabilitar Seleccion</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item class="font-size-sm">Eliminar Seleccion</b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col xl="3" class="ml-3">
              <b-form inline>
                <b-form-input type="search" class="form-control-alt mr-sm-2 mb-sm-0" id="example-if-email2" name="example-if-email2" placeholder="Busqueda" v-model="filter"></b-form-input>
              </b-form>
            </b-col>

            <b-col>
              <b-dropdown variant="light" toggle-class="text-decoration-none" no-caret class="float-right" right>
                <template #button-content>
                  <i class="si si-settings"></i>
                </template>
                <b-dropdown-item class="font-size-sm" href="/clients.csv" download>Exportar Clientes a Excel</b-dropdown-item>
              </b-dropdown>
              <b-dropdown variant="light" toggle-class="text-decoration-none" no-caret class="mr-1 float-right" right>
                <template #button-content>
                  <i class="fas fa-fw fa-filter"></i>
                </template>
                <b-dropdown-item class="font-size-sm" href="#">Por Nombre</b-dropdown-item>
                <b-dropdown-item class="font-size-sm" href="#">Por Departamento</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <br />
          <div class="table-responsive">
            <b-table :items="cotizaciones" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
            </b-table>
          </div>
          <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
            </b-col>
          </b-row>
        </div>
      </base-block>
    </div>
  </div>
</template>

<script>
//import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";
import CRMCotizaciones from "../../../api/admin/crm/CRMCotizaciones";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      cotizaciones: [],
      filter: null,
      view_cliente:false,
      edit : false,
      from:null,
      to:null,
      stats: [],
      isLoading:false,
      moneyFormat:new Intl.NumberFormat('es-MX', this.optionsMoneyFormat),
      fields: [
        {key: 'id',sortable: true, label: '# ID'},
        {key: 'client',formatter: function(value) {
              if(value){
                const jsonStr = JSON.stringify(value)
                const jsonObj = JSON.parse(jsonStr)
                return jsonObj.nombre_comercial;
              }
              return "-";
          },sortable: true, label: 'CLIENTE'},
        {key: 'created_at',formatter: function(value) {
              if(value){
                const event = new Date(value);
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                return event.toLocaleDateString('es-MX', options);
              }
              return "-";
          },sortable: true, label: "Fecha"},
        {key: 'estado',sortable: true, label: "ESTADO"},
        {key: 'total',sortable: true, label: "TOTAL"}
      ],
      
    };
  },
  computed: {
    totalRows() {
      return this.cotizaciones.length
    }
  },
  methods: {

    Procesar(clear){
      const context = this
      if(clear){
        context.from = null,
        context.to = null
      }
      context.isLoading=true
      context.showoverlay = true
      const data = {
        'from': context.from,
        'to': context.to
      }
      let auth = JSON.parse(localStorage.autentication);
      CRMCotizaciones.GetAllQuotsWithStats(auth, data)
      .then((response) => {
        context.cotizaciones = response.data.data.quotes;
        context.stats = response.data.data;
        context.showoverlay = false;
        context.isLoading=false
      })
      .catch((error) => {
        console.log(error);
        context.isLoading=false
      });
    },
   
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

  },

  mounted() {
    let me = this;
    me.Procesar(false);
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>