import Api from "../../Api";

const END_POINT_ALLQUOTSWITHSTATS = 'user/crm/quotes/all-with-stats';


export default {

  GetAllQuotsWithStats(auth, data) {
    return Api.get(END_POINT_ALLQUOTSWITHSTATS, {
        params: {
          "from":data.from,
          "to":data.to
        },
        headers: {
          'Authorization': 'Bearer ' + auth.access_token
        }
      }
    );
  },
}